.chat-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: sans-serif;
    width: 90%;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
    border: 2px solid #3b5998;
  }
  .header {
    background-color: #3b5998;
    color: white;
    width: 100%;
    text-align: center;
  }
  .messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 80%;
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
 
  .user {
    font-weight: bold;
    margin-right: 10px;
    color: #333;
  }
  
  .new-message-form {
    display: flex;
    width: 100%;
    padding: 10px;
  }
  
  .new-message-input {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    color: #333;
    padding: 10px;
    border-radius: 5px 0 0 5px;
  }
  
  .send-button {
    border: none;
    outline: none;
    background: #3b5998;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }